import { Dialog, DialogContent, Picture } from '@troon/ui';
import { Trigger } from '@kobalte/core/dialog';
import { IconPlayButton } from '@troon/icons/play-button';

type Props = {
	code: string;
	poster: string;
};

export function YoutubeVideo(props: Props) {
	return (
		<div
			// eslint-disable-next-line tailwindcss/no-arbitrary-value
			class="relative aspect-[4/3] w-full overflow-hidden rounded"
		>
			<Dialog key="video">
				<Trigger
					as="button"
					class="group size-full outline-none focus-visible:ring-2 focus-visible:ring-brand-100 focus-visible:ring-offset-2"
				>
					<Picture
						src={props.poster}
						// eslint-disable-next-line tailwindcss/no-arbitrary-value
						class="absolute inset-0 z-0 aspect-[4/3] size-full bg-center object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-100"
						width={768}
						height={576}
						sizes="(min-width: 1024px) 60vw, (min-width: 768px) 75vw, 95vw"
						loading="lazy"
					/>
					<span class="absolute inset-0 flex items-center justify-center text-white outline-none focus-visible:ring focus-visible:ring-brand-100 focus-visible:ring-offset-2 active:scale-95 group-hover:text-brand-100/80 motion-safe:transition-all motion-safe:duration-200">
						<IconPlayButton class="size-16" />
						<span class="sr-only">Play video</span>
					</span>
				</Trigger>
				<DialogContent width="fit" noPadding>
					<div class="aspect-video w-svw p-4">
						<iframe
							class="aspect-video size-full rounded-lg"
							src={`https://www.youtube-nocookie.com/embed/${props.code}?autoplay=1&rel=0`}
							title="YouTube video player"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						/>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
